<template>
  <div class='p-5'>
    <router-link :to="this.projectListRoute" class="text-decoration-none font-weight-bold"><span>&#10094;</span>
      Project List</router-link>

    <div class="navigator-container">
      <detail-navigation-bar :tabs="this.tabs" />
      <overview v-if="show_overview"></overview>
      <project-settings-details :canEditProject="canEditProject" v-if="settingsTabActive == 'details'"></project-settings-details>
      <project-settings-scenarios :canEditProject="canEditProject" v-if="settingsTabActive == 'scenarios'" @addNewScenario="addNewScenario" @duplicateScenario="duplicateScenario"></project-settings-scenarios>
      <project-settings-share v-if="settingsTabActive == 'share-settings' && canEditProject" :projectSharesLoaded='projectSharesLoaded'></project-settings-share>
      <project-settings-admin v-if="settingsTabActive == 'admin' && canEditProject"></project-settings-admin>
      <project-settings-resources :canEditProject="canEditProject || userIsRWDI" v-if="settingsTabActive == 'resources'"></project-settings-resources>
    </div>
  </div>
</template>

<script>
import DetailNavigationBar from '@/components/views/navigation/DetailNavigationBar';
import ProjectSettingsAdmin from '@/components/projects/list/ProjectSettingsAdmin';
import ProjectSettingsDetails from '@/components/projects/list/ProjectSettingsDetails';
import Overview from '@/components/projects/detail/navigator/Overview';
import ProjectSettingsResources from '@/components/projects/list/ProjectSettingsResources';
import ProjectSettingsShare from '@/components/projects/list/ProjectSettingsShare';
import ProjectSettingsScenarios from '@/components/projects/list/ProjectSettingsScenarios';
import { GET_COMPANY_USERS, GET_COMPANY } from '@/store/actions/permissions';
import { CHANGE_PROJECT, EDIT_ALL_COMPANY_PROJECTS } from '@/constants/permissions';
import { mapGetters } from 'vuex';

export default {
  name: 'ProjectSettingsContainer',
  components: {
    DetailNavigationBar,
    ProjectSettingsAdmin,
    ProjectSettingsDetails,
    ProjectSettingsResources,
    ProjectSettingsShare,
    ProjectSettingsScenarios,
    Overview
  },
  async created() {
    //created() will be called twice on this componet.  The first time it's called by the vue router initializing and there's no data loaded.  
    //this first call should be ignored and all logic to initialize data should be done in the 2nd create call, when the parent's v-if becomes true
    if (this.selectedProject) {
      if ((this.loggedInUser?.is_superuser || this.userCompany?.is_rwdi)  || (this.selectedProject?.company_id && this.loggedInUser?.company_id == this.selectedProject.company_id)){
        this.$store.dispatch(GET_COMPANY, this.selectedProject.company_id);
      }

      //if the current user can't see any of the tabs (not his company's project or a demo project) then don't bother loading any of the data need
      //to display the tabs (this will also just result in 403 responses from the API since the user won't have permission to them)
      if (this.canEditProject) {
        this.$store.dispatch('project/getProjectAdministrators', Number(this.$route.params.id));
        this.$store.dispatch('project/getProjectSurrounds', Number(this.$route.params.id));
        this.$store.dispatch(GET_COMPANY_USERS, this.companyUsersPage);
        
        await this.$store.dispatch('project/getProjectShares', Number(this.$route.params.id));
        this.projectSharesLoaded = true;
      }
    }
  },
  data() {
    return {
      companyUsersPage: 1,
      projectSearchFilter: undefined,
      projectSharesLoaded: false
    };
  },
  props: {
    selectedProject: {
      required: false //the vue router seems to create an instance of this component and doesn't pass the prop so it can't be required.  I don't know why the router is doing it though....
    }
  },
  computed: {
    show_overview() {
      return this.settingsTabActive == 'navigator' && this.selectedProject?.show_wheel;
    },
    canChangeProject() {
      return (this.$store.getters['allowedPermissions'] || {})[CHANGE_PROJECT];
    },
    canEditAllProjects() {
      return (this.$store.getters['allowedPermissions'] || {})[EDIT_ALL_COMPANY_PROJECTS];
    },
    isProjectAdmin() {
      if (this.selectedProject?.project_administrators.some(x => x.user?.id == this.loggedInUser?.id))
        return true;

      return false;
    },
    isProjectInCompany() {
      return this.loggedInUser?.is_superuser || this.selectedProject?.company_id == this.loggedInUser?.company_id;
    },
    userIsRWDI() {
      return this.userCompany?.is_rwdi;
    },
    canEditProject() {
      if (this.selectedProject?.is_demo_project) {
        return false;
      } else {
        return ((this.canChangeProject && this.isProjectAdmin) || this.canEditAllProjects) && this.isProjectInCompany;
      }
    },
    projectListRoute() {
      if (this.projectSearchFilter) {
        return { name: 'ProjectList', query: { filter: this.projectSearchFilter}  };
      } else {
        return { name: 'ProjectList' };
      }
    },
    tabs() {
      let tab_list = [];

      //order matters
      if (this.selectedProject?.show_wheel) {
        tab_list.push({ name: 'navigator', title: 'Navigator' });
      }
      tab_list.push({ name: 'details', title: 'Details' });
      tab_list.push({ name: 'scenarios', title: 'Scenarios'});
          
      if (this.canEditProject) {
        tab_list.push({ name: 'share-settings', title: 'Share Settings' });
        tab_list.push({ name: 'admin', title: 'Administration' });
      }   
  
      tab_list.push({ name: 'resources', title: 'Resources'});    
      
      return tab_list;
    },
    ...mapGetters('project', ['viewerMode', 'settingsTabActive', 'selectedStudy']),
    ...mapGetters(['loggedInUser', 'userCompany'])
  },
  methods: {
    addNewScenario(projectId, studyId) {
      this.$emit('addNewScenario', projectId, studyId);
    },
    duplicateScenario(projectId, studyId, scenarioData) {
      this.$emit('duplicateScenario', projectId, studyId, scenarioData);
    }
  }
};
</script>

<style scoped>
.navigator-container {
  max-width: 75em;
  margin: 0 auto calc(0.5em + 1.5vw);
}

@media all and (min-width: 30em) {
  .navigator-container {
   padding: 1.5rem;
  }

}

.float-right {
  margin-top: 0;
}
</style>